/**
 * Created by Mr chen on 2021/01/27.
 */

import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

// 墙纸列表
export async function getWallpaperList(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("userHouseBackground", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

// 增
export async function addWallpaper(params) {
  try {
    return await API.post("userHouseBackground", params);
  } catch (error) {
    return error;
  }
}

// 改
export async function updateWallpaper(params) {
  try {
    return await API.post("userHouseBackground/update", params);
  } catch (error) {
    return error;
  }
}

// 删
export async function delWallpaper(ids) {
  try {
    return await API.del("userHouseBackground/" + ids, { ids });
  } catch (error) {
    return error;
  }
}
