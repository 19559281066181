<style lang="less" scoped></style>
<template>
  <el-form
    ref="userForm"
    label-position="left"
    label-width="90px"
    :model="info"
    :rules="rules"
  >
    <el-form-item label="背景标题:" prop="name">
      <el-input
        :maxlength="50"
        v-model="info.name"
        placeholder="请输入名称"
      ></el-input>
    </el-form-item>

    <el-form-item label="背景图片:" prop="url">
      <UploadImg
        :image="info.url"
        @success="(val) => (info.url = val)"
      ></UploadImg>
    </el-form-item>

    <el-form-item class="text-right m-t-md">
      <el-button type="primary" size="large" @click="onSubmit">保存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { addWallpaper, updateWallpaper } from "@/services/wallpaper";
  import UploadImg from "@/components/upload-image";

  export default {
    data() {
      return {
        info: {
          id: "",
          name: "",
          system: 1,
          url: "",
        },
        pager: {
          size: 999,
          page: 1,
        },
        rules: {
          name: [{ required: true, message: "请输入", trigger: "blur" }],
          url: [{ required: true, message: "请上传图片", trigger: "change" }],
        },
      };
    },

    components: {
      UploadImg,
    },

    computed: {
      isEdit() {
        return !!this.propInfo.id;
      },
    },
    props: {
      propVisible: {
        type: Boolean,
        default: function() {
          return false;
        },
      },
      propInfo: {
        type: Object,
        default: function() {
          return {
            id: "",
            name: "",
            system: 1,
            url: "",
          };
        },
      },
    },
    methods: {
      async update() {
        let resData = null;
        if (this.isEdit) {
          resData = await updateWallpaper(this.info);
        } else {
          const info = Object.assign({}, this.info);
          delete info.id;
          resData = await addWallpaper(info);
        }
        if (resData.errorCode === 0) {
          this.$notify.success({
            title: "成功",
            message: this.isEdit ? "修改成功" : "添加成功",
          });
          this.$emit("submit", resData.data);
        }
      },

      /**
       * [提交表单]
       * @return {[type]} [description]
       */
      onSubmit() {
        this.$refs.userForm.validate((valid) => {
          if (valid) {
            this.update();
          }
        });
      },
    },
    async created() {
      if (this.isEdit) {
        this.info = Object.assign({}, this.propInfo);
      }
    },

    watch: {
      propVisible(newVal, oldVal) {
        if (newVal) {
          if (this.isEdit) {
            this.info = Object.assign({}, this.propInfo);
          }

          // this.$refs.userForm.resetFields();
        } else {
          this.info = {
            id: "",
            name: "",
            system: 1,
            url: "",
          };
        }
      },
    },
  };
</script>
