<style lang="less" scoped>
  @import "../../assets/css/variables";

  img {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    color: #bbb;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>壁纸管理</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix" v-on:keyup.13="getDeviceByName">
      <el-table
        @selection-change="(val) => (sels = val)"
        :data="dataList"
        v-loading="listLoading"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>

        <el-table-column label="序号">
          <template slot-scope="scope">
            {{ scope.$index + pager.size * (pager.page - 1) + 1 }}
          </template>
        </el-table-column>

        <el-table-column
          prop="name"
          :show-overflow-tooltip="true"
          label="名称"
        ></el-table-column>

        <el-table-column prop="url" label="图片">
          <template slot-scope="scope">
            <el-image
              lazy
              v-if="scope.row.url"
              style="width: 70px; height: 70px"
              :src="API_ROOT + scope.row.url"
              :preview-src-list="[API_ROOT + scope.row.url]"
            ></el-image>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" size="small">
              修改
            </el-button>

            <el-button type="danger" size="small" @click="handleDel(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button type="primary" @click="handleAdd">新建</el-button>
        <el-button type="danger" @click="batchRemove">批量删除</el-button>

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog
      :title="choosedRow.id ? '修改' : '新增'"
      width="500px"
      :visible.sync="dialogShow"
    >
      <Detail
        :propInfo="choosedRow"
        :propVisible="dialogShow"
        @submit="submit"
      ></Detail>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { API_ROOT, PAGE_SIZE } from "@/config";
  import { getWallpaperList, delWallpaper } from "@/services/wallpaper";
  import Detail from "./detail";

  export default {
    components: { Detail },

    data() {
      return {
        API_ROOT,
        PAGE_SIZE,
        dialogShow: false,
        loaded: false,
        pager: {
          size: PAGE_SIZE,
          page: 1,
          sort: "updateTime,desc",
        },
        total: 1,
        choosedRowIndex: -1,
        choosedRow: {},
        filters: {
          key: "",
        },
        sels: [],
        dataList: [],
      };
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      /**
       * 跳转到编辑
       */
      handleEdit(row) {
        this.choosedRow = row || {};
        this.dialogShow = true;
      },

      submit() {
        this.dialogShow = false;
        this.getList();
      },

      handleAdd() {
        this.choosedRow = {};
        this.dialogShow = true;
      },

      /**
       * 单条数据的删除
       */
      handleDel(row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        }).then(() => {
          delWallpaper(row.id).then((res) => {
            if (res.errorCode === 0) {
              this.$notify.success({
                title: "成功",
                message: "删除成功",
              });
              this.getList();
            }
          });
        });
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        }).then(() => {
          delWallpaper(ids).then((res) => {
            if (res.errorCode === 0) {
              this.$notify.success({
                title: "成功",
                message: "批量删除成功",
              });

              this.getList();
            }
          });
        });
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        this.pager.page = page;
        this.getList();
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.getList();
      },

      /**
       * 获取所有的设备
       */
      async getList() {
        this.listLoading = true;
        const responseData = await getWallpaperList(this.filters, this.pager);
        if (responseData && responseData.data && responseData.errorCode === 0) {
          this.dataList = responseData.data.list || [];
          this.total = responseData.data.total;
        }
        this.listLoading = false;
      },
    },

    async created() {
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }

      this.getList();
    },
  };
</script>
