<style lang="less" scoped>
// @import "../../assets/css/mixin";
.bgImgMixin(@s: contain, @ps: center, @rp: no-repeat) {
  background-repeat: @rp;
  background-position: @ps;
  background-size: @s;
}

.once-image {
  position: relative;
  background-color: #f2f2f2;
  font-size: 24px;
  color: #999;
  border: 1px solid #eee;
  overflow: hidden;
  .bgImgMixin(@s: 100% 100%);
  &.circle {
    .bgImgMixin(@s: contain);
    border-radius: 50%;
  }
  .xiugai {
    position: absolute;
    width: 100%;
    height: 20px;
    line-height: 20px;
    right: -30%;
    top: 8%;
    font-size: 12px;
    color: #f00;
    transform: rotate(45deg);
    background-color: rgba(255,255,255,0.9);
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .el-icon-edit-outline {
    display: none;
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 20px;
    color: #f2f2f2;
  }
  &:hover {
    .el-icon-edit-outline {
      display: block;
    }
  }
}
.upload-icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 24px;
  text-align: center;
  background-color: rgb(248, 248, 248);
  color: rgb(225, 225, 225);
}
.upload-img {
  position: relative;
  .bgImgMixin(@s: contain);
  width: 80px;
  height: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
  border: 1px solid #eee;
  .footer {
    cursor: pointer;
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 22px;
    line-height: 22px;
    padding: 0 8px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #eee;
    font-size: 12px;
    .icon-arrow_left {
      padding-right: 10px;
    }
    .icon {
      font-size: 10px;
      padding-top: 6px;
    }
    &.circle {
      background: transparent;
      color: red;
    }
    .el-icon-back {
      padding-right: 8px;
      &.rt {
        position: relative;
        transform: rotate(180deg);
        top: 5px;
        left: -1px;
        padding-right: 0;
      }
    }
  }
  &:hover .footer {
    display: block;
  }
  &.circle {
    // .bgImgMixin(@s: contain);
    border-radius: 50%;
  }
}
</style>

<template lang="html">
  <div class="upload-content clearfix">
    <div class="upload-img"
      v-for="(item, index) in imageList"
      v-loading="loadList[index]"
      @click.stop.prevent
      :style="{
        width: width,
        height: height,
        backgroundImage: `url(${ API_ROOT + item })`
      }">
      <div class="footer clearfix">
        <i v-if="imageList.length>1&&index>0"
          class="icon el-icon-back pull-left"
          @click="moveToLeft(index)">
        </i>
        <i v-if="imageList.length>1&&index!==imageList.length-1"
          class="icon el-icon-back pull-left rt"
          @click="moveToRight(index)">
        </i>
        <i class="icon el-icon-delete pull-right"
          @click="deleteImg(index)">
        </i>
      </div>
    </div>

    <el-upload
      name="filename"
      :data="{type: params}"
      :action="uploadUrl"
      :disabled="disabled"
      :multiple="multiple"
      :show-file-list="false"
      :http-request="onUpload"
      :before-upload="beforeUpload">
      <div v-if="isOnce">
        <div class="once-image"
          v-loading="loading"
          :class="{circle: type === 'circle'}"
          :style="{
            width: width,
            height: height,
            lineHeight: height,
            backgroundImage: `url(${ imageUrl ? (API_ROOT + imageUrl) : '' })`
          }">
          <slot name="upload-icon">
            <i v-show="!imageUrl" class="el-icon-upload"></i>
          </slot>
          <div class="xiugai" v-show="imageUrl" v-if="type !== 'circle'">修改</div>
          <i class="el-icon-edit-outline" v-else></i>
        </div>
      </div>
      <div v-if="!isOnce && imageList.length < limit"
        class="upload-icon"
        :style="{ width: width, height: height }">
        +
      </div>
    </el-upload>
  </div>
</template>

<script>
import lrz from 'lrz'
import { API_ROOT } from '@/config'
import { uploadImageFile } from '@/services/common'

export default {
  name: 'upload-img',
  data () {
    return {
      API_ROOT,
      loading: false,
      uploadUrl: API_ROOT + '/set_settings/image/upload',
      imageUrl: '',
      imageList: [],
      httpHeaders: {

      }
    }
  },
  props: {
    limit: {
      type: Number,
      default: 5
    },
    isOnce: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '80px'
    },
    height: {
      type: String,
      default: '80px'
    },
    image: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'square' // circle
    },
    imgList: {
      type: Array,
      default () {
        return []
      }
    },
    params: {
      type: [String, Object],
      default: null
    }
  },
  methods: {
    // 上传成功
    onUpload (e) {
      if (this.imageList.length >= this.limit) return
      const file = e.file
      lrz(file, { quality: 0.5 }).then((rst) => {
        if (file.size / 1024 / 1024 < 2) {
          this.uploadImage(file, e.data)
        } else {
          rst.file.name = file.name
          this.uploadImage(rst.file, e.data)
        }
      })
    },

    // 上传
    async uploadImage (file, params) {
      const resData = await uploadImageFile(file, params)
      if (resData && resData.errorCode === 0) {
        const uploadImg = resData.data.fpicurl

        if (this.isOnce) {
          this.loading = false
          this.imageUrl = uploadImg
          this.$emit('success', this.imageUrl)
        } else {
          this.loadList.splice(this.loadList.length - 1, 1, false)
          this.imageList.push(uploadImg)
          this.$emit('success', this.imageList)
        }
      }
    },

    // 左移
    moveToLeft (index) {
      const prev = this.imageList[index - 1]
      this.imageList.splice(index - 1, 1, this.imageList[index])
      this.imageList.splice(index, 1, prev)
      this.$emit('success', this.imageList)
    },

    // 右移
    moveToRight (index) {
      const next = this.imageList[index + 1]
      this.imageList.splice(index + 1, 1, this.imageList[index])
      this.imageList.splice(index, 1, next)
      this.$emit('success', this.imageList)
    },

    // 删除图片
    deleteImg (index) {
      this.loadList.splice(index, 1)
      this.imageList.splice(index, 1)
      this.$emit('success', this.imageList)
    },

    // 上传之前
    beforeUpload (file) {
      const isErrName = file.name.includes(',')
      const isLt2M = file.size / 1024 / 1024 < 2
      const isLimit = this.imageList.length < this.limit
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'

      if (!isLimit) {
        this.$message.error(`上传图片不能超过${this.limit}张`)
      }
      if (!isJPG) {
        this.$message.error('上传的图片文件只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传的图片文件大小不能超过 2MB!')
      }
      if (isErrName) {
        this.$message.error('上传的图片名称不允许有逗号等特殊字符!')
      }
      if (this.isOnce && isJPG && isLt2M) {
        this.loading = true
      }
      if (this.isOnce == false && isJPG && isLt2M && isErrName == false && isLimit) {
        this.loadList.push(true)
      }
      return isJPG && isLt2M && isErrName == false
    }
  },
  mounted () {
    if (this.isOnce) {
      this.imageUrl = this.image
    } else {
      this.imageList = this.imgList || []
      this.loadList = this.imageList.map(item => false)
    }
  },
  watch: {
    imgList: {
      handler (newVal) {
        this.imageList = newVal || []
      },
      deep: true
    },
    image (newVal) {
      this.imageUrl = newVal
    }
  }
}
</script>
